import React from 'react';
import { Toaster, ToastPosition } from 'react-hot-toast';

interface ToasterProps {
  position?: ToastPosition;
  reverseOrder?: boolean;
  gutter?: number;
  containerStyle?: React.CSSProperties;
  containerClassName?: string;
}

const Toast: React.FC<ToasterProps> = ({ position = 'top-right', reverseOrder }) => {
  return <Toaster position={position} reverseOrder={reverseOrder} />;
};

export default Toast;
